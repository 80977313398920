<template>
  <div class="son-page">
    <add-shop
      v-if="editcompondata.disblay"
      @titleSync="editcompongoToView"
      :titleSync.sync="editcompondata"
    ></add-shop>
    <div v-else>
      <div class="top-change">
        <div class="top-search">
          <Form inline :label-width="80">
            <FormItem style="margin: 0" label="商品名称">
              <Input
                placeholder="请输入商品名称"
                v-model="storePrams.keyword"
                style="width: 180px; margin-right: 20px"
              />
            </FormItem>
            <FormItem style="margin: 0" label="商品分类">
              <Select v-model="storePrams.cate_id" style="width: 180px">
                <Option
                  v-for="item in cateList"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.cate_name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem style="margin: 0" label="状态">
              <Select v-model="storePrams.status" style="width: 180px">
                <Option
                  v-for="item in cityList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem style="margin: 0">
              <div @click="searchBtn" class="btn-style">搜索</div>
            </FormItem>
            <FormItem style="margin: 0">
              <Button @click="downloadBtn">下载模板</Button>
            </FormItem>
            <FormItem style="margin: 0">
              <Upload
                :format="['xls', 'xlsx']"
                :on-format-error="handleFormatError"
                :on-success="uploadImgSuccess"
                :on-progress="handleProgress"
                :show-upload-list="false"
                :headers="headers"
                :action="uploadImg"
              >
                <Button>导入商品</Button>
              </Upload>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="table-wrap">
        <div class="add_search_box" style="padding: 20px 0">
          <div>
            <Select v-model="modl" style="width: 180px">
              <Option
                v-for="item in cityList1"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
            <Button class="btn" @click="implement" style="margin-left: 20px"
              >执行</Button
            >
          </div>
          <div>
            <Button style="margin-right: 20px" @click="downloadAll"
              >一键下载商品码</Button
            >
            <Button @click="addShopBtn('add')" class="btn">添加商品</Button>
          </div>
        </div>
        <Table
          @on-selection-change="selectionChange"
          ref="selection"
          stripe
          :columns="worlkColumns"
          :data="data6"
        >
          <template slot-scope="{ index }" slot="name">
            <div>{{ index + 1 }}</div>
          </template>
          <template slot-scope="{ row }" slot="hours">
            <Button @click="setupBtn(row)">营业时间</Button>
          </template>
          <template slot-scope="{ row }" slot="status">
            <i-switch
              @on-change="put(row.id)"
              v-model="row.status"
              size="large"
              :true-value="1"
              :false-value="2"
            >
              <span slot="open">上架</span>
              <span slot="close">下架</span>
            </i-switch>
          </template>
          <template slot-scope="{ row }" slot="recommend">
            <i-switch
              @on-change="recommendBtn($event, row.id)"
              size="large"
              v-model="row.is_recommend"
              :true-value="1"
              :false-value="0"
            >
              <span slot="open">推荐</span>
              <span slot="close">不</span>
            </i-switch>
          </template>
          <template slot-scope="{ row }" slot="kucun">
            <Button @click="seaClick(row)">查询</Button>
          </template>
          <template slot-scope="{ row }" slot="pattern">
            <div @click="patternClick(row)" class="moshi">
              <div v-for="(item, index) of pattern" :key="index">
                <span
                  style="margin: 0 5px"
                  v-if="row.mode.indexOf(item.id.toString()) != -1"
                  >{{ item.mode_name }}</span
                >
              </div>
            </div>
          </template>
          <template slot-scope="{ row }" slot="Code">
            <!-- <div style="width:100%;display:flex;justify-content: space-around;"> -->
            <div style="width: 100%; display: flex">
              <!-- <Button @click="downloadCode(row.id,1)" style="margin-right:10px" v-if="row.mode.indexOf('1')!=-1">堂食码</Button> -->
              <Button
                @click="downloadCode(row.id, 2)"
                style="margin-right: 10px"
                v-if="row.mode.indexOf('2') != -1"
                >外卖码</Button
              >
              <Button
                @click="downloadCode(row.id, 3)"
                v-if="row.mode.indexOf('3') != -1"
                >自取码</Button
              >
            </div>
          </template>
          <template slot-scope="{ row }" slot="operation">
            <!-- <Button @click="put(row)" :type="row.status==1?'warning':'success'" ghost size="small" style="margin-right: 5px">{{row.status==1?'下架':'上架'}}</Button> -->
            <Button
              @click="addShopBtn(row.id)"
              type="info"
              ghost
              size="small"
              style="margin-right: 5px"
              >编辑</Button
            >
            <Button @click="delBtn(row.id)" type="error" ghost size="small"
              >删除</Button
            >
          </template>
        </Table>
        <br />
        <Page
          :total="total"
          :current="storePrams.page"
          style="text-align: right"
          show-elevator
          show-total
          @on-change="pageChange"
          :page-size="storePrams.limit"
        />
      </div>
    </div>
    <Modal
      v-model="setupModal"
      title="商品库存"
      @on-cancel="cancel"
      :mask-closable="false"
      width="760"
      :styles="{ top: '260px' }"
    >
      <div class="kucun_content">
        <Form
          class="left_kucun"
          style="width: 50%"
          label-position="left"
          :label-width="90"
        >
          <div style="padding: 0 0 20px">规格库存</div>
          <div style="padding-left: 20px">
            <FormItem
              v-for="(item, index) of ku.price"
              :key="index"
              :label="item.specs"
            >
              <Input
                style="width: 200px"
                v-model="item.stock"
                placeholder=""
              ></Input>
            </FormItem>
          </div>
        </Form>
        <Form class="right_kucun" label-position="left" :label-width="90">
          <div style="padding: 0 0 20px">加料库存</div>
          <div style="padding-left: 20px">
            <FormItem
              v-for="(item, index) of ku.food"
              :key="index"
              :label="item.food_name"
            >
              <Input
                style="width: 200px"
                v-model="item.stock"
                placeholder=""
              ></Input>
            </FormItem>
          </div>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancel">取消</Button>
        <Button @click="addsetupBtn" type="primary">保存</Button>
      </div>
    </Modal>
    <Modal
      v-model="patternModal"
      title="模式"
      @on-cancel="patterncancel"
      :mask-closable="false"
      width="400"
      :styles="{ top: '260px' }"
    >
      <CheckboxGroup v-model="patternData.patternData" class="fenbu">
        <Checkbox
          v-for="(item, index) of pattern"
          :key="index"
          style="margin-left: 15px"
          :label="item.id"
          >{{ item.mode_name }}</Checkbox
        >
      </CheckboxGroup>
      <div slot="footer">
        <Button @click="patterncancel">取消</Button>
        <Button @click="patternBtn" type="primary">保存</Button>
      </div>
    </Modal>

    <Modal
      v-model="setupTimeModal"
      title="营业时间"
      :mask-closable="false"
      @on-cancel="timeCancel"
      width="600"
      :styles="{ top: '260px' }"
    >
      <Form label-position="top">
        <FormItem label="星期选择">
          <CheckboxGroup class="fenbu" v-model="formItem.week">
            <Checkbox label="1">星期一</Checkbox>
            <Checkbox label="2">星期二</Checkbox>
            <Checkbox label="3">星期三</Checkbox>
            <Checkbox label="4">星期四</Checkbox>
            <Checkbox label="5">星期五</Checkbox>
            <Checkbox label="6">星期六</Checkbox>
            <Checkbox label="0">星期天</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="时间段选择">
          <div
            style="margin-bottom: 10px; display: flex; align-items: center"
            v-for="(item, index) of formItem.business_hours"
            :key="index"
          >
            <TimePicker
              v-model="item.time"
              ref="element"
              @on-change="time($event, index)"
              format="HH:mm"
              type="timerange"
              placement="bottom-end"
              placeholder="点击选择时间"
              style="width: 300px"
            ></TimePicker>
            <Icon
              @click="closeBtn(index)"
              style="color: red; font-size: 20px; margin-left: 10px"
              type="ios-remove-circle-outline"
            />
          </div>
          <div>
            <Button
              @click="addTime"
              style="width: 60px; text-align: cemter"
              icon="md-add"
            ></Button
            ><span style="margin-left: 10px">最多3个时间段</span>
          </div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="timeCancel">取消</Button>
        <Button
          style="background: #ff761d; border-color: #ff761d"
          @click="addTimesetupBtn"
          type="primary"
          >保存</Button
        >
      </div>
    </Modal>
    <!-- 批量编辑操作弹框 -->
    <Modal
      v-model="batchModal"
      :title="batchTitle"
      :mask-closable="false"
      @on-cancel="batchCancel"
      width="600"
      :styles="{ top: '260px' }"
    >
      <Form style="min-height: 200px" v-if="modl == 6" label-position="left">
        <FormItem style="margin: 0" label="商品分类">
          <Select v-model="batchData.cate_id" style="width: 180px">
            <Option v-for="item in cateList" :value="item.id" :key="item.id">{{
              item.cate_name
            }}</Option>
          </Select>
        </FormItem>
      </Form>
      <Form v-if="modl == 7" label-position="top">
        <FormItem label="星期选择">
          <CheckboxGroup class="fenbu" v-model="batchData.week">
            <Checkbox label="1">星期一</Checkbox>
            <Checkbox label="2">星期二</Checkbox>
            <Checkbox label="3">星期三</Checkbox>
            <Checkbox label="4">星期四</Checkbox>
            <Checkbox label="5">星期五</Checkbox>
            <Checkbox label="6">星期六</Checkbox>
            <Checkbox label="0">星期天</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="时间段选择">
          <div
            style="margin-bottom: 10px; display: flex; align-items: center"
            v-for="(item, index) of batchData.business_hours"
            :key="index"
          >
            <TimePicker
              v-model="item.time"
              ref="element"
              @on-change="batchtime($event, index)"
              format="HH:mm"
              type="timerange"
              placement="bottom-end"
              placeholder="点击选择时间"
              style="width: 300px"
            ></TimePicker>
            <Icon
              @click="batchcloseBtn(index)"
              style="color: red; font-size: 20px; margin-left: 10px"
              type="ios-remove-circle-outline"
            />
          </div>
          <div>
            <Button
              @click="batchaddTime"
              style="width: 60px; text-align: cemter"
              icon="md-add"
            ></Button
            ><span style="margin-left: 10px">最多3个时间段</span>
          </div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="batchCancel">取消</Button>
        <Button
          style="background: #ff761d; border-color: #ff761d"
          @click="batchBtn"
          type="primary"
          >确定</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import {
  goodsList,
  getCate,
  goodsDel,
  enable,
  goodsHandle,
  getMode,
  updateMode,
  updateStock,
  goodsRecommend,
  setBusinessHours,
} from "@/api/customer";
import AddShop from "./components/addShop.vue";
export default {
  components: { AddShop },
  name: "canteen",
  data() {
    return {
      uploadImg: config.apiUrl + "/storeapi/goods/import",
      headers: {
        "author-token": getCookies("canyin_token"),
      },
      setupModal: false,
      patternModal: false,
      setupTimeModal: false,
      batchModal: false,
      batchTitle: "",
      batchData: {
        cate_id: "",
        week: [],
        business_hours: [{ start_time: "", end_time: "", time: [] }],
      },
      formItem: {
        id: null,
        week: [],
        business_hours: [{ start_time: "", end_time: "", time: [] }],
      },
      ids: [],
      ku: {
        id: 0,
        food: [],
        price: [],
      },
      patternData: {
        id: 0,
        patternData: [],
      },
      modl: null,
      editcompondata: {
        // 控制组件
        disblay: false,
        id: null,
        type: 1,
        data: {},
      },
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
        keyword: "",
        cate_id: "",
        status: "0",
      },
      pattern: [],
      data6: [],
      worlkColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "#",
          key: "sort",
          width: 60,
          align: "center",
        },
        {
          title: "商品ID",
          key: "id",
          width: 80,
          align: "center",
        },
        {
          title: "商品名称",
          key: "goods_name",
          width: 220,
          align: "center",
        },
        {
          title: "商品分类",
          key: "cate_name",
          width: 160,
          align: "center",
        },
        {
          title: "营业时间",
          slot: "hours",
          width: 140,
          align: "center",
        },
        {
          title: "价格",
          key: "price",
          width: 110,
          align: "center",
        },
        {
          title: "上下架",
          slot: "status",
          width: 120,
          align: "center",
        },
        {
          title: "首页推荐",
          slot: "recommend",
          width: 120,
          align: "center",
        },
        {
          title: "库存",
          slot: "kucun",
          width: 120,
          align: "center",
        },
        {
          title: "支持模式",
          slot: "pattern",
          align: "center",
          width: 200,
        },
        {
          title: "商品码下载",
          slot: "Code",
          align: "center",
          width: 300,
        },
        {
          title: "操作",
          key: "action",
          width: 210,
          fixed: "right",
          align: "center",
          slot: "operation",
        },
      ],
      cateList: [],
      cityList: [
        {
          value: "0",
          label: "所有",
        },
        {
          value: "1",
          label: "上架",
        },
        {
          value: "2",
          label: "下架",
        },
      ],
      cityList1: [
        {
          value: "1",
          label: "全部下架",
        },
        {
          value: "2",
          label: "全部上架",
        },
        {
          value: "3",
          label: "全部删除",
        },
        {
          value: "4",
          label: "库存置满",
        },
        {
          value: "5",
          label: "库存清零",
        },
        {
          value: "6",
          label: "商品分类",
        },
        {
          value: "7",
          label: "营业时间",
        },
      ],
    };
  },
  created() {
    this.getList();
    this.getCate();
    this.getMode();
  },
  methods: {
    getList() {
      goodsList(this.storePrams).then((res) => {
        console.log("1111", res.data.data); //vip_status
        this.total = res.data.total;
        this.data6 = res.data.data;
      });
    },
    delBtn(id) {
      this.$Modal.confirm({
        title: "提示",
        content: `您正在使用删除功能`,
        onOk: () => {
          goodsDel({ id: id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        },
        onCancel: () => {},
      });
    },
    getMode() {
      getMode().then((res) => {
        this.pattern = res.data;
      });
    },
    put(id) {
      // this.$Modal.confirm({
      //   title: '提示',
      //   content: `您正在使用${e.status==1?'下架':'上架'}功能`,
      //   onOk: () => {
      enable({ id: id })
        .then((res) => {
          // if (e.status==1) {
          //   e.status=2
          // }else{
          //   e.status=1
          // }
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
      // },
      // onCancel: () => {
      // }
      // });
    },
    searchBtn() {
      this.storePrams.page = 1;
      this.getList();
    },
    // 下载商品码
    downloadCode(id, mode) {
      location.href = `${config.apiUrl}/storeapi/goods/downloadGoodsQrcode?id=${id}&mode=${mode}&data_id=${this.$store.state.userInfo.data_id}`;
    },
    // 一键下载所有商品码
    downloadAll() {
      location.href = `${config.apiUrl}/storeapi/goods/setBatchGoods?data_id=${this.$store.state.userInfo.data_id}`;
    },
    selectionChange(row) {
      this.ids = [];
      row.forEach((item) => {
        this.ids.push(item.id);
      });
    },
    getCate() {
      getCate().then((res) => {
        this.cateList = res.data;
      });
    },
    implement() {
      if (!this.modl) return this.$Message.warning("请选择执行模式");
      if (this.ids.length == 0) return this.$Message.warning("请选择执行参数");
      let data = {
        id: this.ids,
        type: this.modl,
      };
      if (data.type == 6) {
        console.log("商品分类");
        this.batchTitle = "批量编辑商品分类";
        this.batchModal = true;
      } else if (data.type == 7) {
        console.log("营业时间");
        this.batchTitle = "批量编辑营业时间";
        this.batchModal = true;
      } else {
        goodsHandle(data)
          .then((res) => {
            this.$Message.success(res.msg);
            this.ids = [];
            this.modl = null;
            this.getList();
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    // 批量操作弹框取消按钮
    batchCancel() {
      this.batchData = {
        cate_id: "",
        week: [],
        business_hours: [{ start_time: "", end_time: "", time: [] }],
      };
      this.batchModal = false;
    },
    // 批量操作弹框确定按钮
    batchBtn() {
      let data = {
        id: this.ids,
        type: this.modl,
      };
      if (this.modl == 6) {
        data.cate_id = this.batchData.cate_id;
      }
      if (this.modl == 7) {
        data.week = this.batchData.week;
        data.business_hours = this.batchData.business_hours;
      }
      goodsHandle(data)
        .then((res) => {
          this.$Message.success(res.msg);
          this.ids = [];
          this.modl = null;
          this.getList();
          this.batchCancel();
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    addShopBtn(e) {
      if (e === "add") {
        this.editcompondata.type = 1;
        this.editcompondata.id = null;
        this.editcompondata.disblay = true;
      } else {
        this.editcompondata.type = 2;
        this.editcompondata.disblay = true;
        this.editcompondata.id = e;
      }
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getList();
    },
    recommendBtn(e, id) {
      let data = {
        id: id,
        is_recommend: e,
      };
      goodsRecommend(data)
        .then((res) => {
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    seaClick(e) {
      this.ku.id = e.id;
      this.ku.food = e.food;
      this.ku.price = e.goods_prices;
      this.setupModal = true;
    },
    cancel() {
      this.setupModal = false;
    },
    // 组件回调
    editcompongoToView(e) {
      this.getList();
      this.editcompondata.disblay = e;
    },
    addsetupBtn() {
      updateStock(this.ku)
        .then((res) => {
          this.getList();
          this.$Message.success(res.msg);
          this.patternModal = false;
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
      this.setupModal = false;
    },
    // 设置营业时间弹框
    setupBtn(e) {
      this.formItem.id = e.id;
      this.formItem.business_hours = e.business_hours;
      this.formItem.week = e.week;
      this.setupTimeModal = true;
    },
    // 确定营业时间弹框
    addTimesetupBtn() {
      setBusinessHours(this.formItem)
        .then((res) => {
          this.$Message.success(res.msg);
          this.getList();
          this.setupTimeModal = false;
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    timeCancel() {
      this.setupTimeModal = false;
    },
    // 添加时间段
    addTime() {
      if (this.formItem.business_hours.length < 3) {
        this.formItem.business_hours.push({ start_time: "", end_time: "" });
      } else {
        this.$Message.warning("最多3个时间段");
      }
    },
    time(e, index) {
      if (index == 0) {
        this.formItem.business_hours[0].start_time = e[0];
        this.formItem.business_hours[0].end_time = e[1];
      } else if (index == 1) {
        this.formItem.business_hours[1].start_time = e[0];
        this.formItem.business_hours[1].end_time = e[1];
      } else if (index == 2) {
        this.formItem.business_hours[2].start_time = e[0];
        this.formItem.business_hours[2].end_time = e[1];
      }
    },
    // 删除时间段
    closeBtn(e) {
      this.formItem.business_hours = JSON.parse(
        JSON.stringify(this.formItem.business_hours)
      );
      this.formItem.business_hours.splice(e, 1);
    },
    // 批量弹框添加时间段
    batchaddTime() {
      if (this.batchData.business_hours.length < 3) {
        this.batchData.business_hours.push({ start_time: "", end_time: "" });
      } else {
        this.$Message.warning("最多3个时间段");
      }
    },
    // 批量弹框时间处理
    batchtime(e, index) {
      if (index == 0) {
        this.batchData.business_hours[0].start_time = e[0];
        this.batchData.business_hours[0].end_time = e[1];
      } else if (index == 1) {
        this.batchData.business_hours[1].start_time = e[0];
        this.batchData.business_hours[1].end_time = e[1];
      } else if (index == 2) {
        this.batchData.business_hours[2].start_time = e[0];
        this.batchData.business_hours[2].end_time = e[1];
      }
    },
    // 批量弹框删除时间段
    batchcloseBtn(e) {
      this.batchData.business_hours = JSON.parse(
        JSON.stringify(this.batchData.business_hours)
      );
      this.batchData.business_hours.splice(e, 1);
    },
    patternClick(e) {
      this.patternData.id = e.id;
      this.patternData.patternData = e.mode.map(Number);
      this.patternModal = true;
    },
    patterncancel() {
      this.patternModal = false;
    },
    patternBtn() {
      updateMode({
        id: this.patternData.id,
        mode: this.patternData.patternData,
      })
        .then((res) => {
          this.getList();
          this.$Message.success(res.msg);
          this.patternModal = false;
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    downloadBtn() {
      location.href = `${config.apiUrl}/img/file/goods.xls`;
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    uploadImgSuccess(e) {
      if (e.code == 400) {
        this.$Message.destroy();
        this.$Message.error(e.msg);
      } else {
        this.$Message.destroy();
        this.getList();
        this.$Message.success(e.msg);
      }
    },
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
  },
};
</script>

<style scoped>
.son-page {
  position: relative;
}
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.table-wrap {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.top-search {
  width: 100%;
  background: white;
  padding: 20px 0;
  border-radius: 10px;
  display: flex;
}
.kucun_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.left_kucun {
  width: 50%;
  /* max-height: 100px;
  overflow: auto; */
}
.right_kucun {
  width: 50%;
  /* max-height: 100px;
  overflow: auto; */
}
.two-btns {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: #ff761d;
  width: 80px;
  height: 32px;
}
.order-item {
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.top-num {
  font-size: 13px;
  color: #585858;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.top-num .name1 {
  margin-right: 40px;
}
.bottom-content {
  font-size: 15px;
  margin-top: 20px;
  word-break: break-all;
}
.r-bent {
  width: 200px;
}
.right1 {
  text-align: right;
  margin-bottom: 16px;
}
.l-went {
  flex: 1;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-btn .btn1 {
  width: 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5c11;
  color: #ff5c11;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.ivu-switch-checked {
  border-color: #ff761d;
  background-color: #ff761d;
}
.right-btn .btn2 {
  color: #343434;
  border: 1px solid #555555;
}
.right-btn .btn2:hover,
.right-btn .btn1:hover {
  opacity: 0.6;
}
.add_search_box {
  display: flex;
  justify-content: space-between;
}
.status-pic {
  width: 117px;
  height: 88px;
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
}
.fenbu {
  display: flex;
  justify-content: space-between;
}
.status-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.moshi {
  display: flex;
  background: #fff;
  justify-content: center;
  height: 30px;
  align-items: center;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;
}
.moshi:hover {
  border: 1px solid #57a3f3;
  color: #57a3f3;
  transition: 0.3s all;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #ff761d;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #ff761d;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #ff761d;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  transform-origin: 0 0;
}
.ivu-modal-body {
  max-height: 600px;
  overflow: auto;
}
.ivu-btn-primary {
  background: #ff761d;
  border-color: #ff761d;
}
.ivu-btn-primary:hover {
  background: #ff761d;
  border-color: #ff761d;
}
</style>
